import { useState, useRef, useEffect } from 'react';

import { viacepService } from '../../../../../../../Spread/services/core';

import { estados } from '../../../../../../../commons';

import {
  triggerService,
  patientService,
} from '../../../../../../../Spread/services/core';

import {
  formatDate,
  formatLocalHour,
} from '../../../../../../../Spread/utils/format';
import {
  acionamentoActionsVonage,
  alertActions,
} from '../../../../../../../actions';
import { useDispatch } from 'react-redux';
import { history } from '../../../../../../../helpers';

const useEisteinClinicAndCareForm = (editAcionamento) => {
  const dispatch = useDispatch();
  const [useModal, setModal] = useState(false);
  const [useLoading, setLoading] = useState(false);
  const [usePatient, setPatient] = useState(null);
  const [inAttendance, setInAttendance] = useState(true);

  const formValuesRef = useRef(null);

  const handleModal = (state) => {
    setModal(state);
  };

  const searchAddressByCep = async (cep) =>
    await viacepService.getAddressByCep(cep);

  const setFormValues = (values, setFormikValues) => {
    if (values) {
      setFormikValues('country', 'Brasil');
      setFormikValues('state', estados[values?.uf]);
      setFormikValues('city', values?.localidade);
    }
  };

  const sendMailDTO = () => ({
    email: editAcionamento?.email,
    name: editAcionamento?.name,
    lastName: editAcionamento?.lastName,
    socialName: formValuesRef.current.values.socialName,
    url: editAcionamento?.urlAttendanceVonage,
    date: formatDate(editAcionamento?.appointment),
    hour: formatLocalHour(editAcionamento?.appointment),
    unity: editAcionamento?.unit || '',
  });

  const sendMailTrigger = async () => {
    const body = sendMailDTO();
    setLoading(true);
    const response = await triggerService.sendMail(body);
    setLoading(false);
    return response?.status || false;
  };

  const updateTrigger = async (id) => {
    const values = formValuesRef.current.values;
    const response = await triggerService.updateOne(id, {
      ...values,
      streetCep: values.cep,
    });
    return response?.status || false;
  };

  const getPatientData = async () => {
    const response = await patientService.getOneByDocument(
      editAcionamento.document
    );
    const patient = response?.data?.patient || null;
    patient && setPatient(patient);
  };

  const updatePatient = async (body) => {
    const response = await patientService.updateOne(usePatient?._id, body);
    response?.status && getPatientData();
    dispatch(alertActions.success(response.message));
    return response?.status || false;
  };

  async function handleBack(edit = { isVonage: false }) {
    edit.status = 'output';
    await acionamentoActionsVonage
      .updateStatusAttendance({ ...edit, clear: true })
      .then(() => {
        dispatch(acionamentoActionsVonage.clearAcionamentos());
        history.push('/adm');
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log('erro ao editar', err);
      });
  }

  useEffect(() => {
    getPatientData();
  }, []);

  return {
    useLoading,
    handleModal,
    useModal,
    searchAddressByCep,
    setFormValues,
    sendMailTrigger,
    updateTrigger,
    formValuesRef,
    usePatient,
    updatePatient,
    handleBack,
    inAttendance,
    setInAttendance,
  };
};

export default useEisteinClinicAndCareForm;
