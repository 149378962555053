import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../../../../../../components/Lib/ModalDialog/index';
import ModalAlert from '../../../../../../../components/Lib/ModalAlert';
import {
  CustomAccordion,
  CustomButton,
  CustomSnackbar,
} from '../../../../../../../Spread/components/ui';

import { PatientObservations, ModalNotAttend } from './components';

import { StyledWrapper, StyledQuickActionsWrapper } from './styles';

import { usePatientData } from './hooks/usePatientData';

import { useNavigator } from '../../../../../../../Spread/hooks';

import {
  ContainerSelectModal,
  SelectTitle,
  ValueCheck,
} from '../../../../../QueuePage/styles';

import GenericSelect from '../../../../../GenericSelect';

import { supportReason, objectForFoward } from '../../../../FormAdm/config';
import { removeReason, supportReason, objectForFoward } from './config';

import {
  acionamentoActions,
  actionForwarding,
  modalAlertActions,
} from '../../../../../../../actions';
import { history } from '../../../../../../../helpers';

const PatientData = ({ data, onSave, encounter }) => {
  const { snack, trigger } = CustomSnackbar();
  const { push } = useNavigator();
  const [reasonRemove, setReasonRemove] = useState(0);
  const memorizedData = useMemo(() => data, [JSON.stringify(data)]);
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [reasonSupport, setReasonSupport] = useState(0);
  const [suportError, setSuportError] = useState(false);
  const [itemFoward, setItemFoward] = useState({});
  const dispatch = useDispatch();
  const newModalAlert = useSelector((state) => state.modalAlert);
  const { messageModalAlert, openModalAlert, typeModalAlert } = newModalAlert;
  const [itemDelete, setItemDelete] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const {
    editAcionamento,
    triggerStatus,
    useModalNotAttend,
    setModalNotAttend,
    setTriggerStatus,
  } = usePatientData(trigger);

  const handleCloseModalSupport = () => {
    setReasonSupport(0);
    setOpenModalSupport(false);
  };

  const handleConfirmSupport = () => {
    const { data } = itemFoward;

    if (reasonSupport === '' || reasonSupport === 0) {
      setSuportError(true);
    } else {
      setSuportError(false);
      fowardQueueItem(data, reasonSupport);
      setOpenModalSupport(false);
    }
  };

  const handleChangeReasonSupport = (e) => {
    setReasonSupport(e.target.value);
  };

  const fowardQueueItem = async (data, reason) => {
    const locallUser = JSON.parse(localStorage.getItem('user'));
    objectForFoward.origin.from = locallUser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = locallUser.user.nome;
    objectForFoward.originProfessional.id = locallUser.user._id;
    data.reason = reason;
    data.form = true;
    const dataForFowarding = { ...data, ...objectForFoward };
    dispatch(actionForwarding.forwarding(dataForFowarding));
  };

  const handleCloseNewModal = () => {
    if (messageModalAlert?.includes('redirecionado')) {
      history.push('/adm');
      dispatch(modalAlertActions.clear());
    } else {
      dispatch(modalAlertActions.clear());
      history.push('/adm/acionamento/new');
    }
  };

  const handleReason = (e) => {
    setReasonRemove(e.target.value);
  };

  const handleConfirmDelete = () => {
    const { id, data } = itemDelete;
    if (reasonRemove === '' || reasonRemove === 0) {
      setDeleteError(true);
    } else {
      setDeleteError(false);
      deleteQueueItem(id, reasonRemove, data);
      setOpenDelete(false);
    }
  };

  const deleteQueueItem = (id, reason, pacientData) => {
    dispatch(acionamentoActions.deleteAcionamentosNew(id, reason, pacientData));
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const accordionData = [
    {
      title: 'Visualizar',
      subtitle: 'Observações do paciente',
      body: () => <PatientObservations data={memorizedData} onSave={onSave} />,
    },
    {
      title: 'Ações',
      subtitle: 'Rápidas',
      body: () => (
        <StyledQuickActionsWrapper>
          {!encounter.forkedFrom && (
            <CustomButton
              text='Encaminhar para suporte'
              icon='SendOutlined'
              iconDir='l'
              type='outlined'
              onClick={() => {
                setItemFoward({ data: editAcionamento });
                setOpenModalSupport(true);
              }}
              full
            />
          )}

          <CustomButton
            text='Remover'
            icon='Delete'
            iconDir='l'
            type='outlined'
            onClick={() => {
              setItemDelete({
                id: editAcionamento._id,
                data: editAcionamento,
              });
              setOpenDelete(true);
            }}
            full
          />

          {triggerStatus === 'IN_WAIT_FOR_DATE_SCHEDULE' && (
            <>
              <CustomButton
                text='Contato sem sucesso'
                icon='CallMissedOutgoing'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE');
                }}
                full
              />
              <CustomButton
                text='Confirmar agendamento'
                icon='EventAvailable'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE');
                }}
                full
              />
              <CustomButton
                text='Continuar Atendimento'
                icon='AssignmentInd'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_RECEPCIONIST');
                }}
                full
              />
            </>
          )}
        </StyledQuickActionsWrapper>
      ),
    },
  ];

  return (
    <StyledWrapper>
      <CustomAccordion data={accordionData} />

      <ModalDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
        title={'Deseja remover o acionamento ?'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelOk={'Remover'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCancel={'danger'}
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              height={'medium'}
              value={reasonRemove}
              placeholder='Selecione'
              onChange={handleReason}
              data={removeReason}
            />
          </div>
          {deleteError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>

      <ModalNotAttend
        show={!!useModalNotAttend}
        data={useModalNotAttend}
        onClose={(shouldRedirect = false) => {
          setModalNotAttend(null);

          if (shouldRedirect) {
            push('/adm');
          }
        }}
        trigger={trigger}
      />

      <ModalDialog
        open={openModalSupport}
        handleClose={handleCloseModalSupport}
        handleConfirm={handleConfirmSupport}
        title={'Encaminhamento para o suporte'}
        typeButtomConfirm={'dangerHighlight'}
        typeButtomCancel={'primaryHighlight'}
        labelCancel={'Cancelar'}
        labelOk={'Confimar'}
        withActions
        withClose
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              placeholder='Selecione'
              height={'medium'}
              value={reasonSupport}
              onChange={handleChangeReasonSupport}
              data={supportReason}
            />
          </div>

          {suportError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>
      {openModalAlert && (
        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          close={() => {
            handleCloseNewModal();
          }}
        />
      )}

      {snack}
    </StyledWrapper>
  );
};

export default PatientData;
