import { useDispatch } from 'react-redux';
import { acionamentoActionsVonage } from '../../../actions';
import CustomButton from '../../CustomButtom';
import { IconButton } from '../QueuePage/styles';
import Video from '../../../icons/video.svg';
import * as S from './styles';

const VideoConferenciaButton = ({ data, setInAttendance }) => {
  const dispatch = useDispatch();

  const updatedTime = () => {
    let replacedDate = new Date().toISOString();
    return replacedDate;
  };

  const handleAttendance = (data, setInAttendance) => {
    let editionTime = updatedTime();
    dispatch(
      acionamentoActionsVonage.attendanceAcionamentos(
        { ...data, editionTime },
        '',
        setInAttendance
      )
    );
  };

  return (
    <S.ContainerButton>
      <CustomButton
        onClick={() => {
          handleAttendance(data, setInAttendance);
        }}
        size={'large'}
        typeButtom={'green'}
      >
        <IconButton src={Video} alt='videoconfêrencia' />{' '}
        <span>Videoconfêrencia</span>
      </CustomButton>
    </S.ContainerButton>
  );
};

export default VideoConferenciaButton;
