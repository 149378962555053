import { subMenuConstants } from '../constants';

import { subMenuService } from '../services/subMenu.service';

export const subMenuActions = {
  getAllSubMenuItems,
  getAllSubMenuItemsOnload,
};

function getAllSubMenuItems(result) {
  return (dispatch) => {
    dispatch(success(result));
  };
  function success(result) {
    return { type: subMenuConstants.SUBMENU_SUCCESS, result };
  }
}

function getAllSubMenuItemsOnload(idFila, codfila) {
  // console.log(idFila, "  Recebido");
  return (dispatch) => {
    dispatch(request());
    subMenuService
      .getAllSubmenuItems(idFila, codfila)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: subMenuConstants.SUBMENU_REQUEST };
  }
  function success(result) {
    return { type: subMenuConstants.SUBMENU_SUCCESS, result };
  }
  function failure(error) {
    console.log('error', error);
    return { type: subMenuConstants.SUBMENU_FAILURE, error };
  }
}
