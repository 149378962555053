import { REQUEST_TIMEOUT } from '../constants';
import { filterAccessByProfileDescription } from '../utils/profiles';
import { userService } from './user.service';

require('dotenv').config();
const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const acionamentoVonageService = {
  GetAllAcionamentos,
  deleteAcionamentos,
  confirmedAcionamentos,
  confirmedAcionamentosNovo,
  getCountryAcionamentos,
  getRegionsByCountry,
  getCityByRegions,
  GetAddressFromCep,
  paginateAllAcionamentos,
  getUrlImages,
  attendanceAcionamentos,
  updateStatusAttendance,
  updateStatusAttendance2,
  inAttendance,
  getCountAcionamentos,
  updateStatusConecta,
  verifyAttendance,
  updateAcionamentos,
  cancelScheduleRequest,
};

function cancelScheduleRequest(cancelSchedulePayload) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const { idAttendanceVonage, reasonSelected, reasonInputOther, idSchedule } =
    cancelSchedulePayload;
  const profileDescription = filterAccessByProfileDescription(user?.access);

  const payload = JSON.stringify({
    sessionId: idAttendanceVonage,
    reason: reasonInputOther || reasonSelected,
    name: user?.name,
    idPerfil: profileDescription,
    idSchedule: idSchedule,
  });

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: payload,
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/cancel-appointment`,
      requestOptions,
      2000000
    )
    .then(userService.handleResponse);
}

function getCountAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/count`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getCountryAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/country`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getRegionsByCountry(region) {
  let user = window.localStorage.user;
  let regiao = region ? (region.length <= 0 ? 'nd' : region) : 'nd';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/states/${regiao}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function inAttendance(objectAttendance) {
  let sessionInfos = window.localStorage.user;
  sessionInfos = JSON.parse(sessionInfos);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': sessionInfos.accessToken,
      _id: sessionInfos.user._id,
    },
    body: JSON.stringify(objectAttendance),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/existatt`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function updateStatusAttendance(objectAttendance) {
  let sessionInfos = JSON.parse(window.localStorage.user);
  let { _id } = objectAttendance;
  let bodyReq = {
    attendanceInfos: {
      userId: sessionInfos.user._id,
      userName: ' ',
      attendanceId: '',
      attendanceType: '',
    },
  };

  /*
IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST',
  'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST',
  'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
  'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST',
  'IN_WAIT_ATTENDANCE_DOCTOR',
  'IN_ATTENDANCE_DOCTOR',

  */

  if (
    objectAttendance.clear &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_WAIT_ATTENDANCE_DOCTOR' &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_ATTENDANCE_DOCTOR'
  ) {
    bodyReq = {
      ...objectAttendance,
      attendanceInfos: {
        userId: sessionInfos.user._id,
        userName: ' ',
        attendanceId: ' ',
        attendanceType: 'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST',
        status: objectAttendance.status,
        _id: objectAttendance._id,
        createdAt: objectAttendance.createDate,
      },
    };

    if (objectAttendance.queue) {
      if (
        [
          'IN_VONAGE_PENDING_CONTACT',
          'IN_WAIT_FOR_DATE_SCHEDULE',
          'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE',
        ].includes(objectAttendance.currentEditionAttendanceInfo.attendanceType)
      ) {
        bodyReq.attendanceInfos.attendanceType =
          objectAttendance.currentEditionAttendanceInfo.attendanceType;
      }
    }
  } else if (
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_WAIT_ATTENDANCE_DOCTOR' &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_ATTENDANCE_DOCTOR'
  ) {
    bodyReq = {
      attendanceInfos: {
        userId: sessionInfos.user._id,
        userName: sessionInfos.user.nome,
        attendanceType: 'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
        attendanceId: _id,
        status: objectAttendance.status,
        _id: objectAttendance._id,
      },
    };

    if (objectAttendance.queue) {
      if (
        [
          'IN_VONAGE_PENDING_CONTACT',
          'IN_WAIT_FOR_DATE_SCHEDULE',
          'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE',
        ].includes(objectAttendance.currentEditionAttendanceInfo.attendanceType)
      ) {
        bodyReq.attendanceInfos.attendanceType =
          objectAttendance.currentEditionAttendanceInfo.attendanceType;
      }
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': sessionInfos.accessToken,
      _id: sessionInfos.user._id,
    },
    body: JSON.stringify(bodyReq),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/updatestatus`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((err) => {
      console.log(err);
    });
}

// Seta as infos de atendente do acionamento normal
function updateStatusAttendance2(objectAttendance) {
  const sessionInfos = JSON.parse(window.localStorage.user);
  let { _id } = objectAttendance;

  let bodyReq = {
    attendanceInfos: {
      userId: '',
      userName: ' ',
      attendanceId: '',
      attendanceType: '',
    },
  };

  if (
    objectAttendance.clear &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_WAIT_ATTENDANCE_DOCTOR' &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_ATTENDANCE_DOCTOR'
  ) {
    bodyReq = {
      attendanceInfos: {
        userId: sessionInfos.user._id,
        userName: ' ',
        attendanceId: _id ? _id : '',
        attendanceType: 'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST',
        _id: _id,
        status: objectAttendance.status,
        createdAt: objectAttendance.createDate,
      },
    };
  } else if (
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_WAIT_ATTENDANCE_DOCTOR' &&
    objectAttendance.currentEditionAttendanceInfo?.attendanceType !==
      'IN_ATTENDANCE_DOCTOR'
  ) {
    bodyReq = {
      attendanceInfos: {
        userId: sessionInfos.user._id,
        userName: sessionInfos.user.nome,
        attendanceId: _id,
        status: objectAttendance.status,
        _id: objectAttendance._id,
        attendanceType: 'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST',
      },
    };
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': sessionInfos.accessToken,
      _id: sessionInfos.user._id,
    },
    body: JSON.stringify(bodyReq),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/updatestatus`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((err) => {
      console.log(err);
    });
}

function getCityByRegions(city) {
  let user = window.localStorage.user;
  let cidade = city ? (city.length <= 0 ? 'nda' : city) : 'nda';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/cities/${cidade}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAddressFromCep(cep) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cep/getAddress?cep=${cep}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
function paginateAllAcionamentos(pageSize = 5, page = 1, queryString = '') {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/paginate/${pageSize}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getUrlImages(bucketObject = {}) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      bucketInfo: bucketObject,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/documents/url`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAllAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/getall`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function deleteAcionamentos(
  id,
  idAttendanceVonage,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  let { _id, email, nome, login, telefone } = user.user;
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      reason,
      idAttendanceVonage,
      cancelUser: { _id, email, nome, login, telefone },
      urgencyAttendance,
      urgencyAttendenceReason,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/delete/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmedAcionamentos(acionamento) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  var {
    email,
    name,
    socialName,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    regiao,
    pais,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    idFila,
    all,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    idAttendanceVonage,
    userProducts,
    emailSecondary,
    document,
    isVonage,
    _id,
  } = acionamento;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      createDate: createDate,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nacionalidade === 'B' || 'Brasileira' || 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      nacionalidade: nacionalidade,
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
        role: user?.user?.id_perfil?.descricao,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      idAttendanceVonage: idAttendanceVonage,
      userProducts: userProducts,
      emailSecondary: emailSecondary,
      _id: _id,
      all: all,
      idFila: idFila,
      isVonage: isVonage,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

function confirmedAcionamentosNovo(acionamento) {
  const user = JSON.parse(window.localStorage.user);

  var {
    email,
    email2,
    name,
    socialName,
    lastName,
    document,
    birthDate,
    nationality,
    gender,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    state,
    country,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    disruptiveBehavior,
    cabine,
    docRemovedBy,
    emailSecondary,
    convenioDescricao,
    _id,
    isVonage,
  } = acionamento;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      disruptiveBehavior: disruptiveBehavior,
      createDate: createDate,
      convenio: convenio,
      convenioDescricao: convenioDescricao,
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nationality === 'B' || 'Brasileira' || 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      homePhone: phone2,
      phone2: phone2,
      gender: gender,
      partnerID: id_requisicao_parceiro,
      nationality: nationality,
      naturalness: null,
      city: city,
      state: state,
      country: country,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      docRemovedBy,
      emailSecondary: email2,
      cabine: cabine,
      _id: _id,
      isVonage: isVonage,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

function attendanceAcionamentos(acionamento) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  var {
    email,
    name,
    socialName,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    regiao,
    pais,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    idAttendanceVonage,
    document,
  } = acionamento;
  convenio = convenio && convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      createDate: createDate,
      convenio: convenio ? convenio[0] : '',
      convenioDescricao: convenio ? convenio[1] : '',
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nacionalidade === 'B' || 'Brasileira' || 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
        role: user?.user?.id_perfil?.descricao,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      idAttendanceVonage: idAttendanceVonage,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/attendanceVonage`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

function updateStatusConecta(
  idAttendanceVonage,
  status,
  token,
  userAttendance,
  supportNotCompleted,
  userData
) {
  const accesUser = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'x-access-token': accesUser.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      idAttendanceVonage: idAttendanceVonage,
      queue: status,
      userData: userAttendance,
      supportNotCompleted,
      attendance: userData,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/updateStatusVonage`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function verifyAttendance(id) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      idAcionamento: id,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/verifyAttendance`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((err) => {
      return err;
    });
}

function updateAcionamentos(id, input) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(input),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servicoVonage/adm/acionamentos/update/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((err) => {
      return err;
    });
}
