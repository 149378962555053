export const getChipStyles = (amount: number) => {
    let color;
    let backgroundColor;
  
    if (amount < 3) {
      color = '#136630';
      backgroundColor = '#ebf5ef';
    }
  
    if (amount >= 3 && amount < 5) {
      color = '#8f1b11';
      backgroundColor = '#ffeceb';
    }
  
    if (amount >= 5) {
      color = '#fff';
      backgroundColor = '#d93a2b';
    }
  
    return { color, backgroundColor };
  };
  