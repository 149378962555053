export const Nacionality = [
  { label: 'Brasileira', value: 'B' },
  { label: 'Estrangeira', value: 'E' },
];

export const reasonRemoveData = [
  { label: 'Outros', value: 'Outros' },
  {
    label: 'Paciente não estava conectado ao iniciarmos o contato',
    value: 'Paciente não estava conectado ao iniciarmos o contato',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Atendimento para outro paciente',
    value: 'Atendimento para outro paciente',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Não estava com documento com foto em mãos (Digital ou Físico)',
    value: 'Não estava com documento com foto em mãos (Digital ou Físico)',
  },
  {
    label: 'Não sabia o número da carteirinha',
    value: 'Não sabia o número da carteirinha',
  },
  {
    label: 'Assuntos relacionados ao convênio',
    value: 'Assuntos relacionados ao convênio',
  },
  { label: 'Testes', value: 'Testes' },
  {
    label: 'Queria conhecer o funcionamento do serviço',
    value: 'Queria conhecer o funcionamento do serviço',
  },
  {
    label: 'Dúvidas não atendidas pela telemedicina',
    value: 'Dúvidas não atendidas pela telemedicina',
  },
  {
    label: 'Agendamento de consultas / exames',
    value: 'Agendamento de consultas / exames',
  },
  { label: 'Atendimento de Emergência', value: 'Atendimento de Emergência' },
];

export const ArrayConvenios = [
  'Albert Einstein',
  'Albert Einstein II',
  'Albert Einstein III',
  'Albert Einstein IV 2112',
  'Albert Einstein V',
  'Albert Einstein VI',
];

export const selectDoc = [
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    value: 'PASSPORT',
    label: 'Passaporte',
  },
];

export const Sexo = [
  {
    label: 'Masculino',
    value: 'M',
  },
  {
    label: 'Feminino',
    value: 'F',
  },
];

export const initialValuesFormAdm = {
  email: '',
  email2: '',
  cabine: false,
  vonage: false,
  truclinic: false,
  checkDisruptiveBehavior: false,
  checkUrgencyAttendance: false,
  checkSocialName: false,
  convenio: '',
  convenioDescricao: '',
  name: '',
  socialName: '',
  numberCard: '',
  lastName: '',
  partnerID: '',
  document: '',
  documentType: '',
  birthDate: '',
  phone: '',
  phone2: '',
  gender: '',
  partnerID: '',
  nationality: 'B',
  naturalness: '',
  city: '',
  state: '',
  country: '',
  codPais: '',
  streetCep: '',
  flgNewPassage: '',
  produto: '',
  disruptiveBehavior: '',
  urgencyAttendance: '',
  confirmationUser: {
    _id: '',
    email: '',
    nome: '',
    login: '',
    telefone: '',
  },
  deviceData: {
    deviceType: '',
    OS: '',
    navigator: '',
    route: '/',
    accessChannel: '',
    appVersion: '',
    deviceBrand: '',
  },
  urlAttendanceVonage: '',
  idAttendanceVonage: '',
  docsReferences: [],
  signUpDocuments: [],
  docRemovedBy: {},
  processing: true,
};

export const removeReason = [
  { label: 'Outros', value: 'Outros' },
  {
    label: 'Paciente não estava conectado ao iniciarmos o contato',
    value: 'Paciente não estava conectado ao iniciarmos o contato',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Atendimento para outro paciente',
    value: 'Atendimento para outro paciente',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Não estava com documento com foto em mãos (Digital ou Físico)',
    value: 'Não estava com documento com foto em mãos (Digital ou Físico)',
  },
  {
    label: 'Não sabia o número da carteirinha',
    value: 'Não sabia o número da carteirinha',
  },
  {
    label: 'Assuntos relacionados ao convênio',
    value: 'Assuntos relacionados ao convênio',
  },
  { label: 'Testes', value: 'Testes' },
  {
    label: 'Queria conhecer o funcionamento do serviço',
    value: 'Queria conhecer o funcionamento do serviço',
  },
  {
    label: 'Dúvidas não atendidas pela telemedicina',
    value: 'Dúvidas não atendidas pela telemedicina',
  },
  {
    label: 'Agendamento de consultas / exames',
    value: 'Agendamento de consultas / exames',
  },
  { label: 'Atendimento de Emergência', value: 'Atendimento de Emergência' },
];

export const cancelScheduleReason = [
  { label: 'Outros', value: 'Outros' },
  {
    label: 'Desistência sem Reagendamento - Atraso Profissional',
    value: 'Desistência sem Reagendamento - Atraso Profissional',
  },
  {
    label: 'Desistência sem Reagendamento - Profissional Indisponível',
    value: 'Desistência sem Reagendamento - Profissional Indisponível',
  },
  {
    label: 'Desistência com Reagendamento - Atraso Profissional',
    value: 'Desistência com Reagendamento - Atraso Profissional',
  },
  {
    label: 'Desistência com Reagendamento - Profissional Indisponível',
    value: 'Desistência com Reagendamento - Profissional Indisponível',
  },
];

export const supportReason = [
  {
    value: 'PATIENT_DOESNT_SEE_ME',
    label: 'Paciente não me vê',
  },
  {
    value: 'PATIENT_DONT_HEAR_ME',
    label: 'Paciente não me ouve',
  },
  {
    value: 'PATIENT_DOES_NOT_SEE_ME_AND_DONT_HEAR_ME',
    label: 'Paciente não me vê e não me ouve',
  },
  {
    value: 'DO_NOT_HEAR_THE_PATIENT',
    label: 'Não ouço o paciente',
  },
  {
    value: 'DO_NOT_HEAR_AND_DO_NOT_SEE_THE_PATIENT',
    label: 'Não ouço e não vejo o paciente',
  },
  {
    value: 'NO_COMINICATION',
    label: 'Nenhuma comunicação',
  },
  {
    value: 'UNSTABLE_CONNECTION',
    label: 'Conexão Instável',
  },
];

export const objectForFoward = {
  origin: {
    channel: 'acionamentos',
    received: 'central',
    from: '',
  },
  objectModel: {
    collectionName: 'acionamentosVonage',
    schemaName: 'acionamentosVonage',
  },
  originProfessional: {
    name: '',
    id: '',
  },
};
