import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 470px;

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const StyledQuickActionsWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 10px;

  button {
    width: 60%;

    div {
      justify-content: flex-start;
    }
  }
`;
