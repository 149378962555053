import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
//import { statusAttendancesFactory } from '../../../../cr-improvement/factories/status.attendances.factories';

import Avatar from '../../Avatar';
import JobStatus from '../../JobStatus';
import TableCol from '../../../Lib/TableCol';
import { ReactComponent as UndoIcon } from '../../../../icons/undo.svg';
import { valueNestedProp } from '../../utils/utils';

import { ReactComponent as ClockIcon } from '../../../../icons/agendamentos.svg';


import { QueueProfessionalInfo } from '../QueueProfessionalInfo';

import { getChipStyles } from './variants';

import { FiPhone as FiPhoneIcon } from 'react-icons/fi';

import * as S from './styles';

const ATTENDANCE_SCHEDULE_STATUS = 'IN_WAIT_FOR_DATE_SCHEDULE';

interface QueueItemProps {
  onClick: () => void;
  itemData: Record<string, any>;
  isHover: boolean;
  columns?: { field: string }[];
  children?: React.ReactNode;
  chronometer?: any;
  isClinicQueue?: boolean;
}

export function QueueItem({
  onClick,
  itemData,
  isHover,
  columns,
  children,
  chronometer,
  isClinicQueue,
}: QueueItemProps) {
  const chipStyles = getChipStyles(itemData?.pushCalls?.length || 0);

  const isScheduled = useMemo<boolean>(() => {
    const scheduled =
      itemData?.currentEditionAttendanceInfo?.attendanceType ===
      ATTENDANCE_SCHEDULE_STATUS;

    if (scheduled) return true;

    return false;
  }, [itemData?.currentEditionAttendanceInfo?.attendanceType]);


  //const isPendingContact = statusAttendancesFactory.isPendingContact(itemData);
  return (
    <S.TrCustom
      className={'rubik-regular QueueItem'}
      $isHover={isHover}
      $opacity={1}
      onClick={onClick}
    >
      {/* patient name */}
      <TableCol
        borderTypeColor={
          itemData.isVonage ? 'solid 3px #ff8721' : 'solid 3px #007acc'
        }
        vAlign='middle'
        align='center'
        // maxWidth='100%'
        {...({} as any)}
      >
        <S.ContainerAvatar>
          <Avatar
            onClick={undefined}
            picture={itemData.picture}
            avatarColor={itemData?.avatarColor}
            name={formatTexts(itemData?.name, itemData?.lastName)}
            socialName={formatTexts(itemData?.socialName)}
            iconDisabled={itemData.isScheduled}
            RelogioIcon={<ClockIcon />}
            isWarning={itemData?.notifyHealthProfessionalLate}
            UndoIcon={<UndoIcon/>}
            iconReturned={itemData?.return_to_reception}
          />
        </S.ContainerAvatar>
      </TableCol>

      {/* custom columns data */}
      {columns?.length &&
        columns.map((column) => {
          return (
            column.field !== '' && (
              <S.TableColSpacing
                key={column.field}
                $vAlign='middle'
                align='center'
              >
                <span>
                  {column.field.includes('.')
                    ? valueNestedProp(itemData, column.field)
                    : itemData[column.field]}
                </span>
              </S.TableColSpacing>
            )
          );
        })}

      <QueueProfessionalInfo
        itemData={itemData}
        isClinicQueue={isClinicQueue}
        isScheduled={isScheduled}
      >
        <S.TableColSpacing $vAlign='middle' align='center'>
          <span>{itemData?.currentEditionAttendanceInfo?.userName || ''}</span>
        </S.TableColSpacing>
      </QueueProfessionalInfo>
      

      {/* pushCall */}
      {process.env.REACT_APP_EXECUTE_PUSH_CALL === 'true' && (
        <S.ChipCol>
          <Tooltip title='Tentativa de Contato' placement='bottom'>
            <S.Chip
              $color={chipStyles.color}
              $backgroundColor={chipStyles.backgroundColor}
            >
              <FiPhoneIcon className='icon' />
              <div>{itemData?.pushCalls?.length || 0}</div>
            </S.Chip>
          </Tooltip>
        </S.ChipCol>
      )}

      {/* status */}
      <TableCol
        vAlign='middle'
        align='center'
        maxWidth='140px'
        borderTypeColor={undefined}
      >
        <JobStatus
          cronometer={chronometer}
          acionamento={itemData}
          id={itemData._id}
          attendanceInfo={itemData.currentEditionAttendanceInfo}
          dateStarted={itemData.currentEditionAttendanceInfo?.dateStarted}
          scheduledDateTime={itemData?.isoScheduleTime}
        />
      </TableCol>

      {
      //isPendingContact
      false ? <div /> : children || <div />}
    </S.TrCustom>
  );
}

const formatTexts = (text1?: string, text2?: string): string => {
  let result = '';

  if (text1 && typeof text1 === 'string') {
    result += text1.trim();
  }

  if (text2 && typeof text2 === 'string') {
    // ? why 2 blank spaces?
    result += '  ';
    result += text2.trim();
  }

  return result;
};
