import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CustomButton from '../CustomButton';
import { Container, StatusText, ContainerInfo } from './styles';

const JobStatus = ({
  id,
  acionamento,
  attendanceInfo,
  dateStarted,
  scheduledDateTime,
  cronometer,
}) => {
  const time = cronometer;

  function waitAttendanceReception() {
    switch (attendanceInfo?.attendanceType) {
      case 'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST': {
        return true;
      }
      case 'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST': {
        return true;
      }
      default:
        return false;
    }
  }

  function inAttendanceReceptionist() {
    switch (attendanceInfo?.attendanceType) {
      case 'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST': {
        return true;
      }
      case 'IN_VONAGE_ATTENDANCE_RECEPCIONIST': {
        return true;
      }
      default:
        return false;
    }
  }

  function waitAttendanceSupport() {
    return (
      attendanceInfo?.attendanceType === 'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT' ||
      attendanceInfo?.attendanceType === 'TRUCLINIC_IN_WAIT_ATTENDANCE_SUPPORT'
    );
  }

  function inAttendanceSupport() {
    return (
      attendanceInfo?.attendanceType === 'TRUCLINIC_IN_ATTENDANCE_SUPPORT' ||
      attendanceInfo?.attendanceType === 'VONAGE_IN_ATTENDANCE_SUPPORT'
    );
  }

  function waitAttendanceDoctor() {
    return attendanceInfo?.attendanceType === 'IN_WAIT_ATTENDANCE_DOCTOR';
  }

  function inAttendanceDoctor() {
    return attendanceInfo?.attendanceType === 'IN_ATTENDANCE_DOCTOR';
  }

  function isDriveScheduled() {
    return attendanceInfo?.waitingForDateSchedule;
  }

  function scheduleConfirmContact() {
    if (acionamento.hasOwnProperty('identify_queue')) {
      return (
        attendanceInfo?.attendanceType ===
        'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE'
      );
    }

    return attendanceInfo?.attendanceType === 'IN_WAIT_FOR_DATE_SCHEDULE';
  }

  function pendingContact() {
    return attendanceInfo?.attendanceType === 'IN_VONAGE_PENDING_CONTACT';
  }

  function userMissedAttendance() {
    return attendanceInfo?.attendanceType === 'NO_SHOW';
  }

  const msDifferenceFromDateStarted = moment(time, 'YYYY-MM-DDTHH:mm:ss').diff(
    moment(dateStarted, 'YYYY-MM-DDTHH:mm:ss'),
    'milliseconds'
  );

  function getButtonColor() {
    if (
      inAttendanceDoctor() ||
      inAttendanceSupport() ||
      waitAttendanceDoctor() ||
      inAttendanceReceptionist()
    ) {
      return 'lightBlueRounded';
    }

    if (isDriveScheduled()) {
      return 'greenLightRounded';
    }

    if (userMissedAttendance()) {
      return 'purpleLightRounded';
    }

    if (pendingContact()) {
      return 'pendingLightRounded';
    }

    if (
      msDifferenceFromDateStarted < 180_000 &&
      waitAttendanceReception() &&
      !isDriveScheduled()
    ) {
      return 'greenRounded';
    }

    if (
      msDifferenceFromDateStarted >= 180_000 &&
      msDifferenceFromDateStarted < 300_000 &&
      waitAttendanceReception() &&
      !isDriveScheduled()
    ) {
      return 'onHold';
    }

    if (
      msDifferenceFromDateStarted >= 300_000 &&
      msDifferenceFromDateStarted < 420_000 &&
      waitAttendanceReception() &&
      !isDriveScheduled()
    ) {
      return 'hardOnHold';
    }

    if (
      msDifferenceFromDateStarted >= 420_000 &&
      waitAttendanceReception() &&
      !isDriveScheduled()
    ) {
      return 'dangerRounded';
    }

    if (waitAttendanceSupport() && !isDriveScheduled()) {
      return 'lightBlueRounded';
    }

    return 'greenRounded';
  }

  function getStatusText() {
    if (isDriveScheduled()) {
      return moment(scheduledDateTime).format('DD/MM/YYYY HH:mm');
    }

    if (userMissedAttendance()) {
      return moment(scheduledDateTime).format('DD/MM/YYYY HH:mm');
    }

    if (inAttendanceDoctor()) {
      return 'Em chamada médica';
    }

    if (inAttendanceSupport()) {
      return 'Em atendimento Suporte';
    }

    if (waitAttendanceDoctor()) {
      return 'Em espera médica';
    }

    if (inAttendanceReceptionist()) {
      return 'Em atendimento recepção';
    }

    if (pendingContact()) {
      return 'Contato Pendente';
    }

    if (waitAttendanceReception() && !isDriveScheduled()) {
      return 'Em espera recepção';
    }

    if (waitAttendanceSupport() && !isDriveScheduled()) {
      return 'Em espera suporte';
    }

    return 'Em espera';
  }

  const buttonColor = getButtonColor();
  const statusText = getStatusText();

  if (
    (pendingContact() || scheduleConfirmContact()) &&
    acionamento.hasOwnProperty('identify_queue')
  ) {
    return (
      <Container>
        <CustomButton id={id} typeButtom={buttonColor} fontWeight='bold' noWrap>
          {statusText}
        </CustomButton>
      </Container>
    );
  }

  function getDatetime() {
    const msInHours = moment.duration(msDifferenceFromDateStarted).asHours();

    if (Number.isNaN(msDifferenceFromDateStarted) || msInHours < 0) {
      return '00:00:00';
    }

    const hours = Math.floor(msInHours).toString().padStart(2, '0');
    const minutes = moment(msDifferenceFromDateStarted).format(' mm');
    const seconds = moment(msDifferenceFromDateStarted).format(' ss');

    const datetime = `${hours}:${minutes}:${seconds}`;
    return datetime;
  }

  function getScheduledStatusText() {
    if (isDriveScheduled()) {
      return 'Agendado';
    }

    if (userMissedAttendance()) {
      return 'Faltou';
    }
  }

  const datetime = getDatetime();
  const scheduledStatusText = getScheduledStatusText();

  const shouldShowScheduledStatusText =
    isDriveScheduled() || userMissedAttendance();

  return (
    <Container>
      <CustomButton id={id} typeButtom={buttonColor}>
        <ContainerInfo>
          {shouldShowScheduledStatusText ? scheduledStatusText : datetime}
        </ContainerInfo>
      </CustomButton>

      <StatusText>{statusText}</StatusText>
    </Container>
  );
};

JobStatus.propTypes = {
  id: PropTypes.string,
  acionamento: PropTypes.object,
  attendanceInfo: PropTypes.object,
  dateStarted: PropTypes.string,
  scheduledDateTime: PropTypes.string,
};

export default JobStatus;
